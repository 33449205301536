<template>
  <div class="body">
    <b-row class="mt-3">
      <b-col lg="3"></b-col>
      <b-col cols="12" lg="6">
        <b-card
          border-variant="danger"
          show
          v-if="!hasId"
          class="text-center m-2"
        >
          <h1 class="test-red">Falha de identificação !</h1>
          Por favor entre em contato com (11) 93944-7625
        </b-card>
        <div v-else>
          <transition name="flip" mode="out-in">
            <Welcome @confirmed="confirmUser()" v-if="!confirm"/>
            <Questions v-else />
            
          </transition>
        </div>
      </b-col>
      <b-col lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCard,
  BCol
} from "bootstrap-vue";

import axios from "axios";
import { baseApiUrl } from "@/global";

import Welcome from './components/Welcome.vue'
import Questions from './components/Questions.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,

    Welcome,
    Questions
  },
  data() {
    return {
      imobiliaria: {},
      produtos: [],
      hasId: false,
      confirm: false,
      manager: false,
    };
  },
  methods: {
    async getImob() {
      const imobHash = this.$route.params.id.split("@");
      let produto = imobHash[0];
      let imobiliariaId = imobHash[1];
      await axios
        .get(`${baseApiUrl}/produtos`)
        .then((res) => {
          this.result = res.data;
          for (let i = 0; i < res.data.data.length; i++) {
            this.produtos.unshift(res.data.data[i].db)
          }          
        })
        .catch((err) => console.log(err));
      let db = this.produtos[produto];
      await axios
        .post(`${baseApiUrl}/pesquisa`, {
          db,
          id: imobiliariaId,
        })
        .then((res) => {
          if (res.data.success) {
            this.imobiliaria = res.data.data;
            this.hasId = true;
          }
        });
    },
    confirmUser() {
      this.confirm = true
    }
  },
  mounted() {
    this.getImob();
  },
};
</script>

<style>
@keyframes flip-out {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(90deg);
  }
}

@keyframes flip-in {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.flip-enter-active {
  animation: flip-in 0.3s ease;
}

.flip-leave-active {
  animation: flip-out 0.3s ease;
}
</style>