<template>
  <b-card class="m-2 text-center" border-variant="primary">
    <form-wizard
      color="#428517"
      nextButtonText="Próximo"
      backButtonText="Voltar"
      finishButtonText="Enviar"
      shape=""
      title="PESQUISA"
      subtitle="Pesquisa para os conhecedores do mercado"
      @on-complete="enviar()"
    >
      <div slot="footer" slot-scope="props">
          <b-button @click="props.prevTab()" v-if="props.activeTabIndex > 0" class="m-1" variant="warning">Voltar</b-button>
          <b-button @click="props.nextTab()" variant="primary" v-if="!props.isLastStep">Avançar</b-button>
          <b-button v-else variant="primary" @click="enviar()"> Finalizar</b-button>
      </div>

      <tab-content title="Preço">
        <b-form-group class="m-2">
          <label for="InputHelp">
            <h5>Espectativa de preço por M²</h5>
          </label>

          <b-row class="mt-2">
            <b-col cols="2"> </b-col>
            <b-col cols="8">
              <b-input-group prepend="R$" append=",00 / M²">
                <b-form-input placeholder="" type="number" />
              </b-input-group>
            </b-col>
            <b-col cols="2"> </b-col>
          </b-row>
        </b-form-group>
      </tab-content>
      <tab-content title="Vendas">
        <b-form-group class="m-2">
          <label for="InputHelp">
            <h5>Espectativa de vendas</h5>
          </label>

          <b-row class="mt-2">
            <b-col cols="2"> </b-col>
            <b-col cols="8">
              <b-input-group>
                <b-form-input placeholder="" type="number" />
              </b-input-group>
            </b-col>
            <b-col cols="2"> </b-col>
          </b-row>
        </b-form-group>
      </tab-content>
      <tab-content title="Equipe">
        <b-form-group class="m-2">
          <label for="InputHelp">
            <h5>Quantidade de corretores</h5>
          </label>
          <b-row class="mt-2">
            <b-col cols="2"> </b-col>
            <b-col cols="8">
              <b-input-group>
                <b-form-input placeholder="" type="number" />
              </b-input-group>
            </b-col>
            <b-col cols="2"> </b-col>
          </b-row>
        </b-form-group>
        <div v-if="!manager">
          <b-button variant="primary" @click="manager = true">
            Adicionar Gerente
          </b-button>
        </div>
        <div v-else>
          <b-button variant="danger" @click="manager = false">
            Não adicionar Gerente agora
          </b-button>
          <b-row class="mt-2">
            <b-col cols="12" md="4">
              <b-form-group label="Nome" label-for="">
                <b-form-input />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="E-mail" label-for="full-name">
                <b-form-input id="full-name" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="WhatsApp" label-for="full-name">
                <b-form-input id="full-name" v-mask="'(##)#####-####'" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </tab-content>
    </form-wizard>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";

import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,

    FormWizard,
    TabContent,
  },
  data() {
    return {
      manager: false,
    };
  },
  methods: {
    enviar() {
      alert("foi");
    },
  },
};
</script>

<style>
</style>