<template>
  <b-card class="m-2 text-center" border-variant="primary" v-if="!confirm">
    <h1>Olá Fulano !</h1>
    <p>Aqui vai explicação do que que se trata esse link</p>
    <b-button variant="primary" block @click="$emit('confirmed')">
      Eu sou Fulano e desejo continuar
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
export default {
  props: {
    confirm: Boolean,
  },
  components: {
    BCard,
    BButton,
  },
};
</script>

<style>
</style>